<template>
      <v-app-bar
      flat 
      app
      color="appBar"
      >
        <v-app-bar-nav-icon @click="setMenuLateral()" color="white"></v-app-bar-nav-icon>
        <v-toolbar-title
          style="width: 300px"
          class="ml-0 pl-4"
        >
          <!--span class="hidden-sm-and-down">PPP</span-->
        </v-toolbar-title>
        
        <v-spacer></v-spacer>
        <Cart tipo='menu'></Cart>
        
        <v-divider  
          class="mx-4"
          inset
          vertical>
        </v-divider>
          <!--img
          src="@/assets/img/logo_texto.svg"
          width="8%"
          color="white"
          /-->
        <v-menu bottom left transition="slide-y-transition">
              <template v-slot:activator="{ on, attrs }">
              <v-btn offset-x icon v-bind="attrs" v-on="on" color="white">
                  <i class="fas fa-ellipsis-v"></i>
              </v-btn>
              </template>

              <v-list>                
                  <v-list-item link @click="logout()">
                      <v-list-item-title>Cerrar sesión</v-list-item-title>
                  </v-list-item>
              </v-list>
        </v-menu>

      </v-app-bar>
</template>
<script>
 import { mapMutations } from 'vuex';
 import  Cart  from '@/components/Cart.vue';
export default {
    name:'BarraApp',
     methods: {       
        ...mapMutations(['setMenuLateral']),
        logout: function () {
          this.$store.dispatch('logout');
      },
    },
    components: {
      Cart
    },
}
</script>
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/src/locale/es.ts'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      /*light: {
        primary: '#1688be',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },*/
      light: {
        primary: '#0171CC',
        secondary: '#44BAC7',
        accent: '#82B1FF',
        info: '#2196F3',
        success: '#48CFB2',
        warning: '#FFC800',
        error: '#CE0F6A',
        appBar:'#162742'
      },
    },
  },
  icons: {
    iconfont: 'fa',
  },
  lang: {
    locales: { es },
    current: 'es',
  },
});

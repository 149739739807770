import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/style/custom.css'
import '@fortawesome/fontawesome-free/css/all.css'
import axios from 'axios'
import VModal from 'vue-js-modal'


Vue.config.productionTip = false

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.headers.common['Content-Type'] = 'application/json'
Vue.prototype.$http.defaults.headers.common['Accept'] = 'application/json'

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

Vue.use(VModal)


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

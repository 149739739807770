<template>
     <v-row
            align="center"
            justify="center"
            style="height:100vh"
            class="row-login"
          >
            <v-col
              cols="12"
              sm="8"
              md="5"
            >              
              <v-card cols="12"
              lg="6" md="6" class="fill-height d-flex flex-column justify-center align-center elevation-12"  
              style="background-color:#F5F5F5;width:60rem;border-radius:30px !important;">
                <v-toolbar                
                  flat
                  style="background-color:#F5F5F5"                               
                >
                    <v-col cols="2">                      
                    </v-col>

                    <v-col class="d-flex justify-space-around">
                      <v-toolbar-title class="pt-15 flex text-center"> <img src="@/assets/img/logo_texto.png" width="50%" /></v-toolbar-title>
                    </v-col>
                    <v-col cols="2" class="d-flex justify-end">                     
                    </v-col>             
                
                </v-toolbar>
                <v-card-text class="pb-0">
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                    class="pt-10 mx-auto"
                    style="width: 75%;"                          
                  >
                  
                    <v-text-field
                      label="Usuario"
                      name="usuario"
                      prepend-inner-icon="fas fa-user"
                      type="text"
                      v-model="usuario"
                      :rules="[v => !!v || 'Debe ingresar su usuario']"
                      required
                      solo  
                      :hide-details="true"
                      class="pb-2"                  
                    >
                    <template #prepend-inner>
                     
                        <v-icon style="font-size: 40px !important;" color="appBar">
                          fas fa-user-circle
                        </v-icon>
                     
                    </template>
                    </v-text-field>

                    <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      v-model="password"
                      :rules="[v => !!v || 'Debe ingresar su contraseña']"
                      required
                      @keyup.enter="validar"
                      solo      
                      :hide-details="true"
                    >
                     <template #prepend-inner>

                        <v-avatar
                          color="appBar"
                          size="40"
                        >
                             <v-icon style="font-size: 25px !important;color:#FFF !important" color="appBar" class="rounded-50">
                                fas fa-lock
                              </v-icon>
                        </v-avatar>
                       
                     
                    </template>
                    </v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions class="justify-center pb-3 pt-6">
                 
                  <v-btn color="primary" x-large width="125%" @click="validar" :disabled="!valid || buscando">Iniciar Sesión</v-btn>
                 
                </v-card-actions>
              </v-card>
              <div v-if="buscando">
                  <v-progress-linear
                  indeterminate
                  color="primary"
                  ></v-progress-linear>
              </div>
              
            </v-col>
          </v-row>
</template>
<script>
import {mapState,mapMutations,mapActions} from 'vuex'
export default {
    name:'Login',
    data(){
        return{
            valid: true,
            usuario:null,
            password:null,
            buscando:false,
        }
    },
    computed:{
        ...mapState(['isLoggedIn'])
    },
    methods:{
      ...mapMutations(['setAlert','SnackbarShow']),
      ...mapActions(['getDatosUsuario']),
      validar(){
        if(this.$refs.form.validate()){
           this.login();
        }       
      },
      login: function () {
        this.buscando = true;
        let email = this.usuario
        let password = this.password
        this.$store.dispatch('login', { email, password })
       .then(() => {
          
          this.getDatosUsuario().then(() => {
            this.buscando = false;
            this.$router.push({name:'Home'});
          }).catch(err => {
            console.log(err);
              this.SnackbarShow({
                  tipo: 'error',
                  mensaje: err.message
              });
              this.buscando = false;      
          })
          
       })
       .catch(err => {
        console.log(err);
          this.SnackbarShow({
              tipo: 'error',
              mensaje: err.message
          });
          this.buscando = false;      
       })
      },
    },

}
</script>

<style>
.row-login .v-input__slot{
  height: 4rem;
}
</style>
<template>
	<div id="app">
    
      <v-app id="inspire">
        <div v-if="isLoggedIn" id="contenedor">
          <Navegacion/>
          <v-main> 
          <BarraApp/>          
            <v-container
              fluid
              style="position:relative"             
            > 
              <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                  <v-icon class="pr-3"  x-large>fas fa-{{ snackbar.icon }}</v-icon>
                  <v-layout column>
                    <div>
                      <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div v-html="snackbar.text"></div>
                  </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-snackbar>        
              <v-slide-y-transition mode="out-in">                  
                  <router-view></router-view>
              </v-slide-y-transition>
            </v-container>  
          </v-main>     
          <!--v-footer  class="justify-center pl-0" inset app>
            <v-col
              class="text-center"
              cols="12"          
            >
              {{ new Date().getFullYear() }} — <strong>Desarrollo</strong>
            </v-col>
          </v-footer-->
        </div>
        <div v-else id="contenedor_login">
            <v-main>
             
                <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                  <v-layout align-center pr-4>
                    <v-icon class="pr-3" x-large>{{ snackbar.icon }}</v-icon>
                    <v-layout column>
                      <div>
                        <strong>{{ snackbar.title }}</strong>
                      </div>
                      <div v-html="snackbar.text"></div>
                    </v-layout>
                  </v-layout>
                  <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                    <v-icon>clear</v-icon>
                  </v-btn>
                </v-snackbar>

              <v-container
              fluid
              style="position:relative"
              >
                <router-view/>
              </v-container>
            </v-main>
        </div>
      </v-app>
   
</div>
</template>

<script>
import BarraApp from '@/components/BarraApp.vue' 
import Navegacion from '@/components/Navegacion.vue' 
import {mapState,mapMutations} from 'vuex'
export default {
  name: 'App',
  components: {
			BarraApp,
      Navegacion
	}, 
  data: () => ({
    
  }),
  computed : {
    ...mapState(['msg_alert','snackbar']),
      isLoggedIn(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      ...mapMutations(['setAlert','SnackbarShow']),
      logout() {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      }
    },
    created: function () {
     
        this.$http.interceptors.response.use(
            response => {
                return response;
            },
            error => {
                if (error.response) {
                    switch (error.response.status) {
                        case 401:
                            
                            this.$store.dispatch('logout').then(() => {
                                this.$router.push({name:'Login'})
                            })
                            this.setAlert({
                                tipo: 'warning',
                                mensaje: 'La sesión ha caducado.'
                            });
                           
                    }
                }
                        return Promise.reject (error.response.data) // devuelve el mensaje de error devuelto por la interfaz
            });
    }
};
</script>

<style>
div[data-app='true'] {
  background: url('assets/img/fondo_general.svg') no-repeat center center fixed !important;
  background-size: cover !important;
}
</style>

<template>
    <div>
        
        <v-card
        class="mx-auto card_home"        
        elevation="10"
        style="width:40rem;height:20rem;"   
        round      
        >
          
            <v-card-title class="layout" style="padding-top:6rem;padding-left:2rem" ><span class="primary--text font-weight-bold" style="font-size:4rem"> ¡Bienvenido! </span></v-card-title>
            <v-card-subtitle class="layout justify-center" style="padding-top:3rem"> <span style="font-size:2.5rem;color:black"> A nuestro sistema </span></v-card-subtitle>           
            
        </v-card>

       
    </div>
</template>

<script>

  export default {
    name: 'Home',
    data: () => ({
            items: [
                {
                    text: 'Inicio',
                    disabled: true,
                }
            ],            
        }),
  }
</script>

<style>
.card_home{
    border-radius:30px !important;
}
</style>

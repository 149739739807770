<template>
    <v-navigation-drawer
        v-model="drawer"        
        fixed
        app
        permanent
        :expand-on-hover="menu_lateral"
      >

       <v-divider></v-divider>

            <v-list-item class="px-2">
                <v-list-item-avatar>                    
                    <v-icon style="font-size: 40px !important;" color="warning">
                      fas fa-user-circle
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-title>{{ usuario.name }}</v-list-item-title>
            </v-list-item>

        <v-divider></v-divider>

      <v-list dense nav>
            <v-list-item link exact :to="{name:'Home'}">
              <v-list-item-action>
                <v-icon>fas fa-home</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                Inicio
              </v-list-item-title>
            </v-list-item>

            <v-list-item link exact :to="{name:'Productos'}" v-if="verificarPermiso(1)">
              <v-list-item-action>
                <v-icon>fas fa-cubes</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                Productos
              </v-list-item-title>
            </v-list-item>

            <v-list-item link exact :to="{name:'Cotizaciones'}"  v-if="verificarPermiso(19)">
              <v-list-item-action>
                <v-icon>fas fa-file-invoice-dollar</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                Cotizaciones
              </v-list-item-title>
            </v-list-item>
           
            <v-list-item link exact :to="{name:'Cotizar'}" v-if="verificarPermiso(5)">
              <v-list-item-action>
                <v-icon>fas fa-dollar-sign</v-icon>
              </v-list-item-action>
              <v-list-item-title>
                Cotizar Producto
              </v-list-item-title>
            </v-list-item>



        <v-list-group :value="admin_ppp" no-action v-if="verificarPermiso(7) || verificarPermiso(11) || verificarPermiso(15)">
                    <template v-slot:activator>
                       <v-list-item-action>
                        <v-icon>fas fa-gears</v-icon>
                      </v-list-item-action>
                      <v-list-item-title link>Administrador</v-list-item-title>
                    </template>                     

                    <v-list-item link :to="{name:'Propiedades'}" exact v-if="verificarPermiso(7)">
                      <v-list-item-title class="ml-6">
                        Propiedades
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link :to="{name:'Piezas'}" exact v-if="verificarPermiso(11)">
                      <v-list-item-title class="ml-6">
                        Piezas
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link :to="{name:'Clientes'}" exact v-if="verificarPermiso(15)">
                      <v-list-item-title class="ml-6">
                        Clientes
                      </v-list-item-title>
                    </v-list-item>  

          </v-list-group>

          <v-list-group :value="admin" no-action v-if="verificarPermiso(36) || verificarPermiso(24) || verificarPermiso(28) || verificarPermiso(32)">
                    <template v-slot:activator>
                       <v-list-item-action>
                        <v-icon>fas fa-gears</v-icon>
                      </v-list-item-action>
                      <v-list-item-title link>Admin Sistema</v-list-item-title>
                    </template>

                    <v-list-item link :to="{name:'Usuarios'}" exact v-if="verificarPermiso(36)">                      
                      <v-list-item-title class="ml-6">
                        Usuarios
                      </v-list-item-title>
                    </v-list-item>       

                    <v-list-item link :to="{name:'Roles'}" exact v-if="verificarPermiso(24)">
                      <v-list-item-title class="ml-6">
                        Roles
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item link :to="{name:'GrupoPermisos'}" exact v-if="verificarPermiso(28)">
                      <v-list-item-title class="ml-6">
                        Grupo de permisos
                      </v-list-item-title>
                    </v-list-item> 

                    <v-list-item link :to="{name:'Permisos'}" exact v-if="verificarPermiso(32)">                      
                      <v-list-item-title class="ml-6">
                        Permisos
                      </v-list-item-title>
                    </v-list-item>                  

          </v-list-group>

          
        </v-list>


      </v-navigation-drawer>
</template>
<script>
import {mapState,mapActions} from 'vuex'
import * as checkPermiso from '@/config/checkPermisos.js'
export default {
    name:'Navegacion',
    data:() => ({
            drawer: true,
            admin:false,
            admin_ppp:false,
            tiene_permiso:false      
      
    }),
    computed: {
        ...mapState(['menu_lateral','usuario'])
    },
    methods:{      
      verificarPermiso(permiso){
          return checkPermiso.tienePermiso(permiso);
      }     
    },
    created(){
      
        let url_actual = location.href;
        
        if ( String(url_actual).indexOf('/usuarios') != -1 ) this.admin = true;
        if ( String(url_actual).indexOf('/roles') != -1 ) this.admin = true;
        if ( String(url_actual).indexOf('/permisos') != -1 ) this.admin = true;
        if ( String(url_actual).indexOf('/grupo_permisos') != -1 ) this.admin = true;  
        
        if ( String(url_actual).indexOf('/sites') != -1 ) this.admin_ppp = true;
        if ( String(url_actual).indexOf('/cupos_observaciones') != -1 ) this.admin_ppp = true;
        
    }

}
</script>
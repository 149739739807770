import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '@/router/index.js';
import * as CONFIG from '@/config/config.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    menu_lateral: localStorage.getItem('menu_lateral') == 'true' ? true : false,
    usuario: JSON.parse(localStorage.getItem('usuario')) || {},
    token: localStorage.getItem('token') || '',
    msg_alert: {tipo:'', mensaje:''},
    snackbar: {
      color: null,
      icon: null,
      mode: null,
      position: "top",
      text: null,
      timeout: 7500,
      title: null,
      visible: false
    },
    timeout: 7500,
    itemsCart:{
      total:0,
      items:[]
    }

  },
  mutations: {
    setMenuLateral(state){
			state.menu_lateral = !state.menu_lateral;
			localStorage.setItem('menu_lateral', Boolean(state.menu_lateral));
		},
    setAlert(state, alerta){
			if (alerta != null){
				state.msg_alert.tipo = alerta.tipo;
				state.msg_alert.mensaje = alerta.mensaje;
			}else{
				state.msg_alert = {tipo:'', mensaje:''};
			}
		},
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, payload){
      //state.status = payload.estado
      state.token = payload.token
      state.usuario = payload.usuario
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      //state.status = ''
      state.token = ''
      state.usuario = {}
    },
    SnackbarShow(state,mensaje) {
      if (!mensaje.tipo) return;
      switch (mensaje.tipo) {
        case "error":
          state.snackbar = {
            color: "error",
            icon: "error",
            mode: "multi-line",
            position: "top",
            timeout: 4000,
            title: "Error",
            text: mensaje.mensaje,
            visible: true
          };
          break;
        case "info":
          state.snackbar = {
            color: "info",
            icon: "info",
            mode: "multi-line",
            position: "top",
            timeout: 0,
            title: "Información",
            text: mensaje.mensaje,
            visible: true
          };
          break;
        case "success":
          state.snackbar = {
            color: "success",
            icon: "check_circle",
            mode: "multi-line",
            position: "top",
            timeout: 4000,
            title: "Bién!",
            text: mensaje.mensaje,
            visible: true
          };
          break;
        case "warning":
          state.snackbar = {
            color: "warning",
            icon: "warning",
            mode: "multi-line",
            position: "top",
            timeout: 4000,
            title: "Atención!",
            text: mensaje.mensaje,
            visible: true
          };
          break;
      }
    },
    dataitemsCart(state, payload){
      //state.status = payload.estado
      state.itemsCart.total = payload.total
      state.itemsCart.items = payload.items
    },
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: CONFIG.urlApi+'login', data: user, method: 'POST' })
        .then(resp => {
          const token = resp.data.access_token
          localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
          
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          reject(err)
        })
      })
    },
    logout({commit}){      
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('token')
        localStorage.removeItem('permisos')
        localStorage.removeItem('vista_programacion')
        delete axios.defaults.headers.common['Authorization']
        router.push({name: 'Login'});
      })
    },
    tienePermiso({commit},permiso){
     
        let permisos_login = localStorage.getItem('permisos');       
          let tiene_permiso = false;
          if(permisos_login.indexOf(permiso) != -1){
            tiene_permiso = true;
          }        
          return tiene_permiso;
		},
    getDatosUsuario({commit}){
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'datosUsuario',data:{} ,method: 'GET' })
        .then(resp => {

          localStorage.setItem('usuario', JSON.stringify(resp.data.data.usuario));
          
          /**
           * PERMISOS
           */
          let permisos_usuario = [];
          if(resp.data.data.permisos.length > 0){
            resp.data.data.permisos.forEach(permiso => {             
              permisos_usuario.push(permiso);
            });
            localStorage.setItem('permisos', permisos_usuario);
          }  
          
          commit('auth_success', {
						//status: estado,
						usuario: JSON.parse(localStorage.getItem('usuario')),
						token: localStorage.getItem('token')
					});

          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    },
    getServicios({commit,dispatch},cuenta_id){

      return new Promise((resolve, reject) => {        
        axios({url: CONFIG.urlApi+'servicios/get_servicios_cuenta',data:{cuenta_id:cuenta_id},method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)         
        })
        .catch(err => {          
          reject(err)
        })
      })
    },
    getAgentes({commit,dispatch},cuentaServicio){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'agentes/get_agentes',data:cuentaServicio,method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    },
    getRestricciones({commit,dispatch},cuentaServicio){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'restricciones/get_restricciones',data:cuentaServicio,method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    },
    getRestriccion({commit,dispatch},restriccion_id){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'restricciones/get_restriccion',data:{id:restriccion_id},method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)
        })
        .catch(err => {          
          reject(err)
        })
      })
    },
    getRestriccionServicio({commit,dispatch},servicio_id){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'restricciones/get_restriccion_servicio',data:{servicio_id:servicio_id},method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)
        })
        .catch(err => {          
          reject(err)
        })
      })
    },
    getCargos({commit,dispatch}){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'cargos/get_cargos',data:{},method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token
          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    },
    getSimulaciones({commit,dispatch},cuentaServicio){
      
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'simulaciones/get_simulaciones',data:cuentaServicio,method: 'POST' })
        .then(resp => {
          const token = resp.data.jwt
          //localStorage.setItem('token', token)
          axios.defaults.headers.common['Authorization'] = token          
          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    },
    getItemsCart({commit,dispatch}){
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'getItemsCart',data:{},method:'POST'})
        .then(resp => {
          commit('dataitemsCart',{
						//status: estado,
						total: resp.data.data.total,
						items: resp.data.data.items
					});

          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })        

      })
    },
    getDetalleCarrito({commit,dispatch},bonificacion){
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'getDetalleCarrito',data:{bonificacion},method:'POST'})
        .then(resp => {
          resolve(resp)
        })
        .catch(err => {          
          reject(err)
        })        

      })
    },
    deleteItemCart({commit,dispatch},id){
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'deleteItemCart',data:{id},method:'POST'})
        .then(resp => {
          dispatch('getItemsCart')
          resolve(resp)
        })
        .catch(err => {          
          reject(err)
        })        

      })
    },
    getToken({commit,dispatch}){
      return new Promise((resolve, reject) => {
        
        axios({url: CONFIG.urlApi+'test',data:{},method:'GET'})
        .then(resp => {

          resolve(resp)
        })
        .catch(err => {
          
          reject(err)
        })
      })
    }
  },  
  modules: {
  },
  getters:{
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status
  }
})

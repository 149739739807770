

<template>
    <div>
            <v-menu           
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
            v-if="tipo=='menu'"
            >
            <template v-slot:activator="{ on, attrs }">
               <v-btn
                icon
                v-bind="attrs"
                v-on="on"
                color="white"
                >
                    <v-icon>fas fa-shopping-cart</v-icon>
                </v-btn>
            </template>

            <v-card>                
                
                <v-list v-if="itemsCart.items.length">
                

                    <v-list-item v-for="(item,index) in itemsCart.items" :key="index">
                        <v-list-item-avatar>
                        <img
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                        >
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>${{ item.price }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon @click="deleteItem(item.id)">
                            <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item link :to="{name:'ListaProductos'}" class="text-center">
                        <v-list-item-title>Ver carrito</v-list-item-title>
                    </v-list-item>
                </v-list>
                <v-list v-else>
                     <v-list-item class="text-center">
                        <v-list-item-title>No hay productos</v-list-item-title>
                    </v-list-item>
                </v-list>
               
            </v-card>
            </v-menu>
        

            <template v-if="tipo=='list'">
                <v-list v-if="itemsCart.items.length">
                    <v-list-item v-for="(item,index) in itemsCart.items" :key="index">
                        <v-list-item-avatar>
                        <img
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                        >
                        </v-list-item-avatar>

                        <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                        <v-list-item-subtitle>${{ item.price }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon @click="deleteItem(item.id)">
                            <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <v-list v-else>
                     <v-list-item class="text-center">
                        <v-list-item-title>No hay productos en el carrito</v-list-item-title>
                    </v-list-item>
                </v-list>
               
            </template>
            
    </div>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
    name:'Cart',
    props:{
        tipo:[String]
    },
     data(){
      return {
        buscando:false,
      }
    },
    methods:{
        ...mapActions(['getItemsCart','getToken','deleteItemCart']),
        deleteItem(id){
            this.deleteItemCart(id)
        }
    },
    computed: {
        ...mapState(['itemsCart'])
    },
    created(){
        this.getItemsCart();
    }
}
</script>
export function tienePermiso(permiso) {
    let permisos_login = localStorage.getItem('permisos').split(','); 
    permisos_login = permisos_login.map(i=>Number(i));
    let tiene_permiso = false;
    if(permisos_login != null){
      //if(permisos_login.indexOf(permiso) != -1){      
      //  tiene_permiso = true;
      //} 
     
        tiene_permiso = permisos_login.some(function(el) {
          return el === permiso;
      });
    }
           
    return tiene_permiso; 
}